import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FaArrowLeftLong } from "react-icons/fa6";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import "../../App.css";

function SelectOptionPage({ currentOptions, currentItem, setCommodityList }) {
  const navigate = useNavigate();
  const { itemId } = useParams();
  console.log(currentItem, "curItem");
  console.log(currentOptions, "curOpts");
  const [stepsPrice, setStepsPrice] = useState(currentItem.price);
  const [displayOptions, setDisplayOptions] = useState([]);
  const [AddInCart, setAddInCart] = useState(false);

  const [topping, setTopping] = useState("10'INCHES");
  const [cheese, setCheese] = useState("Yes");
  const [meat, setMeat] = useState("Yes");

  const onOptionChange = (e) => {
    setTopping(e.target.value);
  };
  const onOptionChange2 = (e) => {
    setCheese(e.target.value);
  };
  const onOptionChange3 = (e) => {
    setMeat(e.target.value);
  };

  const getCheckedOptions = () => {
    console.log(document.querySelectorAll('input[type="checkbox"]:checked'));
  };

  const handleAddToCart = (e) => {
    e.preventDefault();
    let arrMain = [];
    let choosedOptions = [];
   


    const checkedCheckboxes = document.querySelectorAll("input[type=checkbox]:checked");
    if (checkedCheckboxes.length === 0) {
      // If no checkboxes are selected, add the product directly to the cart
      choosedOptions.push({
        stepName: "Added 1",
        shortName: "Successfully added",
        type: " ",
        price: 0, // Set a default price or leave it as 0
        selected: true,
        optId: "no_options_id",
      });
    }else{
      document
        .querySelectorAll("input[type=checkbox]:checked")
        .forEach((inp) => arrMain.push(inp.id));

      currentOptions.stepsToChoose?.forEach((step) =>
        step.options.forEach((opt) => {
          if (arrMain.includes(opt._id)) {
            choosedOptions.push({
              stepName: step.stepName,
              shortName: step.shortName,
              type: opt.type,
              price: opt.price,
              selected: true,
              optId: opt._id,
            });
          }
        })
      );
    }

    const tempCommodity = {
      barcode: "testBarcode",
      name: currentItem.name,
      productPrice: stepsPrice,
      options: choosedOptions,
      unit: 1,
    };
    if (currentItem.steps) tempCommodity.stepsId = currentItem.steps;

    setDisplayOptions(filterArray(tempCommodity.options));
    setAddInCart(true);
    
    console.log("tempComm: =============", tempCommodity);

    setCommodityList((comm) => {
      // Retrieve existing commodity list from local storage
      const existingCommodities = JSON.parse(localStorage.getItem("commodityList")) || [];

      // Check if the commodity already exists in the local storage based on name
      const existingIndex = existingCommodities.findIndex(item => item.name === tempCommodity.name);
      console.log("existingCommodities: =============", existingCommodities);
      console.log("existingIndex: =============", existingIndex);

      if (existingIndex !== -1) {
        // If the commodity already exists, update it
        existingCommodities[existingIndex] = tempCommodity;
        localStorage.setItem("commodityList", JSON.stringify(existingCommodities));
        return existingCommodities;
      } else {
        // If the commodity doesn't exist, add it to the local storage
        const updatedCommodities = [...comm, tempCommodity];
        localStorage.setItem("commodityList", JSON.stringify(updatedCommodities));
        return updatedCommodities;
      }
    }); 
  };




  

  const filterArray = (inputArray) => {
    return inputArray.reduce((acc, current) => {
      const existingItem = acc.find(
        (item) => item.stepName === current.stepName
      );
      if (existingItem) {
        existingItem.type.push(current.type);
      } else {
        acc.push({
          ...current,
          type: [current.type],
        });
      }

      return acc;
    }, []);
  };

  return (
    <div class="pt-[5rem]">
      <div class="my-[5rem]">
        <h3 className="text-center">Options</h3>
        <Container
          className="card container p-5 mt-4 font-sans"
          style={{ backgroundColor: "rgba(244, 244, 244, 1)" }}
        >
          <Row>
            <Col sm={12} md={8} lg={8}>
              <div className="border-r-2 border-gray-400">
                <div style={{ color: "#E57C35" }}>
                  <p style={{ cursor: "pointer" }}>
                    <FaArrowLeftLong onClick={() => navigate(-1)} />
                  </p>
                  <span>
                    <h4 className="mt-[-39px] ml-[47px] font-bold">
                      {currentItem.name || " "}
                    </h4>
                  </span>
                </div>
                <span>
                  <p style={{ marginLeft: "44px" }}>
                    {currentItem.description || " "}
                  </p>
                </span>
                {currentOptions?.stepsToChoose.map((step) => {
                  return (
                    <Step
                      key={step._id}
                      stepToChoose={step}
                      setStepsPrice={setStepsPrice}
                      getCheckedOptions={getCheckedOptions}
                      handleAddToCart={handleAddToCart}
                    />
                  );
                })}

                <Button
                  onClick={handleAddToCart}
                  variant="outline-secondary text-black"
                  style={{ marginTop: "30px", marginLeft: "50px" }}
                  id=""
                >
                  Add to Cart
                </Button>
              </div>
            </Col>
            <Col sm={12} md={4} lg={4}>
              <h4 class="text-center mt-5">TOTAL </h4>
              {displayOptions?.map((opt) => {
                return (
                  <>
                    <p>
                      {opt.shortName}{" "}
                      <strong>
                        {opt.type.map((o) =>
                          opt.type.length === 1 ? o + "" : o + ", "
                        )}
                      </strong>

                    </p>

                  </>
                  
                );
              })}
              {AddInCart? (
                <>
                <br/>
                  <h5 style={{ color:"#e17a34", fontWeight:"bolder"}}>
                    Your Selected Items added into the cart
                  </h5>
                </>
              ):null}
             
              <div class="d-flex col mt-[150px] justify-between">
                <div>
                  <p className=" text-xl font-semibold"> Total </p>
                </div>

                <div>
                  {/* <p>£8.99</p> */}
                  <p
                    className=" text-xl font-semibold"
                    style={{ color: "#E57C35" }}
                  >
                    {" "}
                    £{Math.round(stepsPrice * 100) / 100}
                  </p>
                </div>
              </div>

              {/* <Link to="/cartview"> */}
              <div className="d-flex w-100 gap-3 justify-content-center">
                <button
                  onClick={() => navigate("/cartview")}
                  className="text-white w-50 p-2 rounded"
                  style={{ backgroundColor: "#E57C35" }}
                >
                  Checkout
                </button>
                <button
                  onClick={() => navigate(-1)}
                  className="text-white w-50 p-2 rounded"
                  style={{ backgroundColor: "#E57C35" }}
                >
                  Continue Shopping
                </button>
              </div>
              {/* </Link> */}
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

function Step({ stepToChoose, setStepsPrice, handleAddToCart }) {
  return (
    <>
      <h6 className="mt-4 ml-[47px]  font-semibold ">
        {stepToChoose.stepName}
      </h6>
      <div class="d-flex flex-col ml-[47px]   justify-center items-start">
        <Option
          options={stepToChoose.options}
          stepName={stepToChoose.stepName}
          setStepsPrice={setStepsPrice}
        />
      </div>
    </>
  );
}

function Option({ options, stepName, setStepsPrice }) {
  const [selectedCheckboxes, setSelectedCheckboxes] = React.useState([]);

  const handleCheckboxChange = (value, stepNm, optPrice) => {
    let max = 1;
    if (stepNm.toLowerCase().includes("any"))
      max = Number(stepNm.replace(/[^0-9]/g, ""));
    // Check if the checkbox is already selected
    if (selectedCheckboxes.includes(value)) {
      // If selected, remove it from the array
      setStepsPrice(
        (stepsPrices) => Math.round((stepsPrices - optPrice) * 100) / 100
      );
      setSelectedCheckboxes(
        selectedCheckboxes.filter((checkbox) => checkbox !== value)
      );
    } else {
      // If not selected, check if the maximum limit is reached
      if (selectedCheckboxes.length < max) {
        // If not reached, add the checkbox to the array
        setStepsPrice(
          (stepsPrices) => Math.round((stepsPrices + optPrice) * 100) / 100
        );
        setSelectedCheckboxes([...selectedCheckboxes, value]);
      }
    }
  };

  return (
    <>
      {options.map((option) => {
        return (
          <div key={option._id} class="option-container">
            <input
              type="checkbox"
              name={option.type}
              value={option._id}
              id={option._id}
              checked={selectedCheckboxes.includes(option._id)}
              onChange={(e) => {
                handleCheckboxChange(option._id, stepName, option.price);
              }}
            />
            <label htmlFor={option._id} class="type-label px-2">
              {option.type}
            </label>
            <label htmlFor={option._id} class="price-label">
              £{option.price}
            </label>
          </div>

        );
      })}
    </>

  );
}

export default SelectOptionPage;
