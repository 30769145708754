import React from "react";
import { Link, Outlet, useNavigate, useParams } from "react-router-dom";
import { data } from "../../data/data.js";
import { apiUrl } from "../../data/env.js";

const ProductCategory = ({
  subCategories,
  handleAddToCart,
  handleRemoveFromCart,
  cart,
}) => {
  const { id } = useParams();
  // console.log(id, "this is id");
  const filteredSubcategories = subCategories.filter(
    (sub) => sub.productCategory === id
  );
  // console.log(filteredSubcategories);
  // const project = data;
  return (
    <div class="pt-[5rem] ">
      <p class="fs-5 mx-5 my-5">
        &lt;
        <span class="underline underline-offset-2 cursor-pointer pl-1 fs-5">
          <Link to="/" class="text-black">
            back
          </Link>
        </span>
      </p>

      {/* <<<<<<< HEAD */}
      {/* <span class="text-white rounded-r-full py-2 px-4 fs-5 my-5 font-semibold bg-gradient-to-r from-orange-500 to-orange-300">
        PIZZA
      </span>

      <div className="grid sm:grid-cols-2 md:grid-cols-4 gap-4 my-5 mx-4 px-5">
        {data.map((item, index) => (
          <div
            key={index}
            class="d-flex flex-col justify-around items-center container "
          >
            <div class="relative">
              <img src={item.image} alt="" class="w-[200px] h-[200px] image" />
              <p class="absolute top-[21px] right-[2px] text-white rounded-l-lg  fs-5 p-2 font-semibold bg-[#FE2B2B]">
                {item.price}
              </p>
            </div>

            <h6 class="text text-center font-semibold w-[12rem] pt-2 uppercase hover:text-black no-underline">
              {item.name}
            </h6>
            <p class="text text-slate-600 text-center px-4 font-semibold no-underline">
              {item.desc}
            </p>
            <Link to={`/selectOption`}>
              <button class="text-center font-semibold  uppercase px-5 py-2 text-white rounded-md bg-[#59A453]">
                Order Now
              </button>
            </Link>
          </div>
        ))}
      </div> */}
      {/* ======= */}
      {filteredSubcategories.map((filSubcat) => (
        <SubCategory
          key={filSubcat._id}
          subCat={filSubcat}
          allsubcat={subCategories}
          handleAddToCart={handleAddToCart}
          handleRemoveFromCart={handleRemoveFromCart}
          cart={cart}
        />
      ))}
      
      {/* >>>>>>> 85cf74723bd7481de1f8bbfa7bb87e0ad985001b */}

      <Outlet />
    </div>
  );
};

function SubCategory({ subCat, handleAddToCart, handleRemoveFromCart, cart, allsubcat }) {
  const navigate = useNavigate();
  // console.log("Mmmmmmmmmmmm====>>>", allsubcat)
  return (
    <span>
 <div style={{minHeight:"160vh"}}>
      <span class="text-white rounded-r-full py-2 px-4 fs-5 my-5 font-semibold bg-gradient-to-r from-orange-500 to-orange-300">
        {subCat.name}
      </span>  
        {
          subCat.name === "Burgers" ||
          subCat.name === "Pizzas" ||
          subCat.name === "Wraps (Signature)" ||  
          subCat.name === "Salad Bowls" || 
          subCat.name === "Fruit Flavour" || 
          subCat.name === "Waffles" ||
          subCat.name === "Chocolate Bar Milkshakes"  || 
          subCat.name === "Sundaes" ||
          subCat.name === "Cookie Dough" || 
          subCat.name === "Ice Cream / Gelato"  || 
          subCat.name === "Savers Menu / Sides" || 
          subCat.name === "Cold Drinks"  ||
          subCat.name === "Crisps" ||
          subCat.name === "Sauce Dips (2 oz)" ||
          subCat.name === "Doughnuts" ? (
            <>

                <br />
                <div style={{ float: "left", marginRight: "20px" }}> {/* Added container */}
                  <br />
                  <br />
                  <Link to="/productCategories/6559386e26ceebe4f9b2d9c2" style={{ textDecoration: "none" }}>
                    <span class="text-white rounded-r-full py-2 px-4 fs-5 my-5 font-semibold bg-gradient-to-r from-orange-500 to-orange-300" style={{ cursor: "pointer" }}>
                      Pizzas&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                  </Link>


                  <br />
                  <br />
                  <Link to="/productCategories/6559386e26ceebe4f9b2d9eb" style={{ textDecoration: "none" }}>
                    <span class="text-white rounded-r-full py-2 px-4 fs-5 my-5 font-semibold bg-gradient-to-r from-orange-500 to-orange-300" style={{ cursor: "pointer" }}>
                      Wraps&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                  </Link>

                  <br />
                  <br />
                  <Link to="/productCategories/6559386e26ceebe4f9b2da08" style={{ textDecoration: "none" }}>
                    <span class="text-white rounded-r-full py-2 px-4 fs-5 my-5 font-semibold bg-gradient-to-r from-orange-500 to-orange-300" style={{ cursor: "pointer" }}>
                      Burgers&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                  </Link>

                  <br />
                  <br />
                  <Link to="/productCategories/6586c29b25c1c303b82a3418" style={{ textDecoration: "none" }}>
                    <span class="text-white rounded-r-full py-2 px-4 fs-5 my-5 font-semibold bg-gradient-to-r from-orange-500 to-orange-300" style={{ cursor: "pointer" }}>
                      Salad Bowl&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                  </Link>

                  <br />
                  <br />
                  <Link to="/productCategories/6559386e26ceebe4f9b2da21" style={{ textDecoration: "none" }}>
                    <span class="text-white rounded-r-full py-2 px-4 fs-5 my-5 font-semibold bg-gradient-to-r from-orange-500 to-orange-300" style={{ cursor: "pointer" }}>
                      Bubble Tea&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                  </Link>

                  <br />
                  <br />
                  <Link to="/productCategories/6559386e26ceebe4f9b2da3f" style={{ textDecoration: "none" }}>
                    <span class="text-white rounded-r-full py-2 px-4 fs-5 my-5 font-semibold bg-gradient-to-r from-orange-500 to-orange-300" style={{ cursor: "pointer" }}>
                      Waffles&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                  </Link>

                  <br />
                  <br />
                  <Link to="/productCategories/6559386e26ceebe4f9b2da6d" style={{ textDecoration: "none" }}>
                    <span class="text-white rounded-r-full py-2 px-4 fs-5 my-5 font-semibold bg-gradient-to-r from-orange-500 to-orange-300" style={{ cursor: "pointer" }}>
                      MilkShakes&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                  </Link>

                  <br />
                  <br />
                  <Link to="/productCategories/6559386e26ceebe4f9b2da85" style={{ textDecoration: "none" }}>
                    <span class="text-white rounded-r-full py-2 px-4 fs-5 my-5 font-semibold bg-gradient-to-r from-orange-500 to-orange-300" style={{ cursor: "pointer" }}>
                      Sundaes&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                  </Link>

                  <br />
                  <br />
                  <Link to="/productCategories/6559386e26ceebe4f9b2da86" style={{ textDecoration: "none" }}>
                    <span class="text-white rounded-r-full py-2 px-4 fs-5 my-5 font-semibold bg-gradient-to-r from-orange-500 to-orange-300" style={{ cursor: "pointer" }}>
                      Cookie Dough&nbsp;&nbsp;
                    </span>
                  </Link>

                  <br />
                  <br />
                  <Link to="/productCategories/6559386e26ceebe4f9b2da87" style={{ textDecoration: "none" }}>
                    <span class="text-white rounded-r-full py-2 px-4 fs-5 my-5 font-semibold bg-gradient-to-r from-orange-500 to-orange-300" style={{ cursor: "pointer" }}>
                      Ice-Cream&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                  </Link>

                  <br />
                  <br />
                  <Link to="/productCategories/6559386e26ceebe4f9b2da8c" style={{ textDecoration: "none" }}>
                    <span class="text-white rounded-r-full py-2 px-4 fs-5 my-5 font-semibold bg-gradient-to-r from-orange-500 to-orange-300" style={{ cursor: "pointer" }}>
                      Saver Menu&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                  </Link>

                  <br />
                  <br />
                  <Link to="/productCategories/6559386e26ceebe4f9b2da8d" style={{ textDecoration: "none" }}>
                    <span class="text-white rounded-r-full py-2 px-4 fs-5 my-5 font-semibold bg-gradient-to-r from-orange-500 to-orange-300" style={{ cursor: "pointer" }}>
                      Drinks&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                  </Link>

                  <br />
                  <br />
                  <Link to="/productCategories/6559386e26ceebe4f9b2da8e" style={{ textDecoration: "none" }}>
                    <span class="text-white rounded-r-full py-2 px-4 fs-5 my-5 font-semibold bg-gradient-to-r from-orange-500 to-orange-300" style={{ cursor: "pointer" }}>
                      Crisps & Cookie
                    </span>
                  </Link>

                  <br />
                  <br />
                  <Link to="/productCategories/6559386e26ceebe4f9b2da8f" style={{ textDecoration: "none" }}>
                    <span class="text-white rounded-r-full py-2 px-4 fs-5 my-5 font-semibold bg-gradient-to-r from-orange-500 to-orange-300" style={{ cursor: "pointer" }}>
                      Sauce Dips&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                  </Link>

                  <br />
                  <br />
                  <Link to="/productCategories/6559386e26ceebe4f9b2da90" style={{ textDecoration: "none" }}>
                    <span class="text-white rounded-r-full py-2 px-4 fs-5 my-5 font-semibold bg-gradient-to-r from-orange-500 to-orange-300" style={{ cursor: "pointer" }}>
                      Doughnuts&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                  </Link> 
                </div> 


            </>
          ):(
            <span>
                <br />
                <span style={{ float: "left", marginRight: "20px" }}>
                  <br />
                  <br />
                  <Link style={{ textDecoration: "none" }}>
                    <span class="text-white rounded-r-full py-2 px-4 fs-5 my-5 font-semibold bg-gradient-to-r from-white-500 to-white-300" >
                      Pizzas&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                  </Link>
                </span>
            </span>
          )}
   

      <div className="grid sm:grid-cols-2 md:grid-cols-4 gap-4 my-5 mx-4 px-5" >
        {subCat?.productsList?.map((item) => (
          <div
            key={item._id}
            class="d-flex flex-col justify-center items-center"
          >
            <div class="relative">
              <img
                src={`${apiUrl}/images/products/${item.image}`}
                alt=""
                class="w-[200px] h-[200px] "
              />
              {item.price ? (
                <p class="absolute top-[21px] right-[2px] text-white rounded-l-lg  fs-5 p-2 font-semibold bg-[#FE2B2B]">
                  {item.price}
                </p>
              ) : null}
            </div>

            <h6 class="text-center font-semibold w-[12rem] pt-2 uppercase hover:text-black no-underline">
              {item.name}
            </h6>
          
            {cart.some((c) => c._id === item._id) ? (
              <button
                class="text-center font-semibold  uppercase px-5 py-2 text-white rounded-md bg-[#59A453]"
                onClick={() => handleRemoveFromCart(item._id)}
              >
                Added into cart
              </button>
            ) : (
              <button
                class="text-center font-semibold  uppercase px-5 py-2 text-white rounded-md bg-[#59A453]"
                onClick={() => {
                  handleAddToCart(item);
                  navigate(`/selectOption/${item._id}`);
                }}
              >
                Choose Options
              </button>
            )}
            {/* </Link> */}
          </div>
        ))}
      </div>
      </div>
    </span>
  );
}

export default ProductCategory;
